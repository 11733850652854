import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class PdfDownloadService {
  responseData: any;
  IMAGES_SERVER_URL: string;

  constructor(
    @Inject('appUrls') appUrls: any, 
    private http: HttpClient,
    private router: Router
  ) {
    this.IMAGES_SERVER_URL = appUrls.IMAGES_SERVER_URL;
  }

  getPdf(uuid, user_id) {
    const url = this.IMAGES_SERVER_URL + '/get-pdf';
    const options = {
      responseType: 'blob',
      params: new HttpParams().set('user_id', user_id).set('uuid', uuid),
    };

    //@ts-expect-error // Because wtf if we don't it claims responsetype needs to be json but it is not...
    return this.http.get(url, options);
  }

  chatStream(body) {
    const options = {
      responseType: 'blob',
      params: new HttpParams()
        .set('user_id', body.user_id)
        .set('uuid', body.uuid),
    };

    const user_id = body.user_id;
    const url = this.IMAGES_SERVER_URL + '/get-pdf';
    return new Observable<string>((observer) => {
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({user_id:user_id, uuid: body.uuid }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${user_id}`,
        },
      })
      
    });
  }
}