/* eslint-disable @typescript-eslint/no-unused-vars */

import { forkJoin as observableForkJoin, Observable, throwError as _throw } from 'rxjs';
//src/app/features/images/services/imageSave.service.ts

import { Injectable, Inject, EventEmitter } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Router } from '@angular/router';

import {
    UUID,
    //contentHeaders,
    //multiPartHeaders
} from '@sybl/common-models';

import {
    IImageInfo,
    IImageUploadInfo,
    IAWSSaveResponse
} from '@sybl/feature-images-models';

import {
    UploadInput,
    UploadFile} from '@sybl/feature-images-models';

interface PostParts {
    dataType: string,
    dataPosition: number,
    data: any, //base64
    imageUUID?: string
}
export interface Upload {
    progress: number
    state: 'PENDING' | 'IN_PROGRESS' | 'DONE'
  }

@Injectable()
export class ImageSaveService {
    responseData: any;
    uploadInput: EventEmitter<UploadInput>;
    IMAGES_SERVER_URL: string;


    constructor(
        @Inject('appUrls') appUrls: any,
        private http: HttpClient,
        private router: Router
    ) {
        this.IMAGES_SERVER_URL = appUrls.IMAGES_SERVER_URL

        //this.imageServerUrl = appUrls.IMAGES_SERVER_URL;
        //Check to see if the observable has already been instantiated
        // this.initializeDataService();
        this.http = http;
        this.uploadInput = new EventEmitter<UploadInput>();
    }

    // Is not being called anywhere at the moment...
    lookForImages(text:string, logged_in_user:any) {
        //Need to get just the base64 of the image, should be able to parese out the size>>
        const postParts: Array<PostParts> = [];
        const images: Array<any> = [];
        let dataPosition = 0;
        let textDone = '';
        let myBaseString = '';

        // Images are represented as base64 image of the format <div><img src="data:image/png;base64, asdanfnafnanfah..."></div>
        // Images can be of type, jpeg, svg, png, 
        const splitImages = text.split('<img src="')

        // See if there is any text before the image is inserted, push it to postParts array,
        if (splitImages[0] !== undefined) {
            postParts.push({ dataType: 'text', dataPosition: dataPosition, data: splitImages[0] })
            dataPosition++;
        }
        const numberOfImages = splitImages.length;


        //Run through the number of images and split each part into it's image portion and text
        for (let i = 1; i < numberOfImages; i++) {
            //The images end on "></div>     This may not be the best split to make, need to protect for user error.
            // TODO: Really need to change this to websiteURL and use an config file to call.
            //const imageURL = "https://192.168.1.106/images/raw/"

            let imagePartSplit = [];
            let imgSrc = '';
            let width = null;
            let afterImageText = '';
            /** 
             * Image name is a base64 encoded value of the posting user_id and a unique identifier.
            */
            const imageUUID = logged_in_user + ':' + new UUID().UUID();
            const encodedImageId = btoa(imageUUID);

            const dirtyCheckToSeeIfTextInBetweenImages = splitImages[i].split('>')
            if (dirtyCheckToSeeIfTextInBetweenImages[1] !== undefined && dirtyCheckToSeeIfTextInBetweenImages[1].length > 1) {
                afterImageText = afterImageText + dirtyCheckToSeeIfTextInBetweenImages[1];
            }


            // First transformation gets the image guts split out
            if (splitImages[i].includes('</div>') === true) {

                imagePartSplit = splitImages[i].split('</div>');
                myBaseString = imagePartSplit[0];

                const numberOfParts = imagePartSplit.length;

                for (let j = 1; j < numberOfParts; j++) {

                    //TODO important this is a check to make sure we're not duplicating stuff it is not a very robust check :(
                    if (imagePartSplit[j] !== '') {
                        if (!afterImageText.includes(imagePartSplit[j])) {
                            afterImageText = '<br/>' + afterImageText + imagePartSplit[j] + '</div>'
                        }
                    } else
                        if (!afterImageText.includes(imagePartSplit[j])) {

                            afterImageText = afterImageText + imagePartSplit[j] + '</div>'
                        }
                }

                //Need to check if there is text after an image on the same line, without a new div.
                if (splitImages[i].includes('>')) {
                    const getStringOnSameLine = splitImages[i].split('>');
                    const beforeLastPartLength = afterImageText.length;

                    //remove the last </div> and append the text.

                    //So we need to figure out when to stop us from getting here if we've already added the info to the post.
                    //TODO Important, at the moment this is a quick way to stop duplication of text being appended.
                    if (!afterImageText.includes(getStringOnSameLine[1])) {
                        afterImageText = afterImageText + getStringOnSameLine[1]
                    } 
                }

                //Each other div is a section of text after the image, will need to understand the postion and reconsitute.
            }
            else {
                // If there are two images in a row, there is no div to split against but there is a stray " at the end which needs to be removed
                myBaseString = splitImages[i]
                //May need to reattach width to image... Because of firefox issue with svg
            }

            /**
             * Deal with resized Images
             * When the image is resized, width property is added by image-resize-module, need to remove it and use it
             */
            if (myBaseString.includes("width=")) {
                //<img src="AASDAS...aYFUCKGmVmJrmwq7NmzYq//Z" width="517" style=" 
                const splitOutWidth = myBaseString.split('" width="');
                const widthSplit = splitOutWidth[1].split('"');

                //Width needs to be converted to number as parsing it from dataUrl it is a string.
                width = Number(widthSplit[0]);
                imgSrc = splitOutWidth[0];

            } else if (myBaseString.includes('">')) {
                // If there is an image next to an image there may be an extra "
                imgSrc = myBaseString.replace('">', '');

            }
            // Jpeg and png do not need to be handled differently
            else {
                imgSrc = myBaseString;
            }
            // Parse the string to separate data and contentType 
            const block = myBaseString.split(";");

            // Get the content type
            const dataType = block[0].split(":")[1];// In this case "image/png"
            const dataTypeSplit = dataType.split('image/')
            const fileExt = dataTypeSplit[1];
            const imageType = 'image/' + fileExt
            const filename = encodedImageId + '.' + fileExt;


            // TODO look at why i COmmented out below 
            //const imageFileUrl = imageURL + filename;
            //const imgSrcInsert = '<img src="' + imageFileUrl + '">'

            const widthNumber = Number(width)

            images.push({ filename: filename, imgSrc: imgSrc, imageType: imageType, width: widthNumber, status: 'pending' });

            if (myBaseString !== "") {
                //  postParts.push({ dataType: 'text', dataPosition: dataPosition, data: imgSrcInsert });
            }

            dataPosition++;
            //Push whatever text there are after the images.
            postParts.push({ dataType: 'text', dataPosition: dataPosition, data: afterImageText })

        }

        postParts.map(post => {
            if (post.dataType === 'text') {
                textDone = textDone + post.data
            }

        })
        //Separating text and images
        return { text: textDone, images: images }
    }
    addImageInfo() {
        // IN theory this is where we would push the imageInfo into the allImageInfo$
    }

    removeImageInfo() {
        //Remove the image by splice from allImageInfos$
    }


    saveAvatarToServer(imageUploadInfo: IImageUploadInfo, data:any): any { //, filename, originalname): Observable<any> {
        const pageUrl = imageUploadInfo.pageUrl;
        const user_id = imageUploadInfo.user_id;
        const jwtToken = imageUploadInfo.jwtToken;
        const uuid = imageUploadInfo.uuid;
        const imageUuid = imageUploadInfo.imageUuid;
        const imageUrl = this.IMAGES_SERVER_URL + "/image/avatar"

        const headers = new Headers();
        /** In Angular 5, including the header Content-Type can invalidate your request */
        //headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        if (data !== undefined) {

            const body: IImageUploadInfo = {
                jwtToken: jwtToken,
                pageUrl: pageUrl,
                user_id: user_id,
                width: imageUploadInfo.width,
                height: imageUploadInfo.height,
                format: imageUploadInfo.format,
                uuid: uuid,
                imageUuid: imageUuid,
                imageBase64: data,
            }


            this.uploadInput = new EventEmitter<UploadInput>();

            return this.http.post(imageUrl, body, {})
                .pipe(
                    map((res: any) => { //IHttpResponse
                        const resBody = res;//JSON.parse(res._body);

                        if (resBody.msg === "IMAGE_UPLOAD_SUCCESS") {

                            return resBody;

                        } else {

                            // Need to do something with image not saved error.
                        }


                    }),
                    catchError(err => {
                        console.warn('Error Saving Image to Base64', err)
                        return err
                    })
                )
        }
    }

    savePdfToServer(imageUploadInfo: any, file:File): any{
        const pageUrl = imageUploadInfo.pageUrl;
        const user_id = imageUploadInfo.user_id;
        const jwtToken = imageUploadInfo.jwtToken;
        const uuid = imageUploadInfo.uuid;
        const imageUrl = this.IMAGES_SERVER_URL + "/pdf"

        const httpOptions = '';
          
        if (file !== undefined) {
          /*  const body: any = {
                jwtToken: jwtToken,
                pageUrl: pageUrl,
                user_id: user_id,
                uuid: uuid,
                file: file
            }
*/
            const body = new FormData();
            body.append('file', file, file.name);
            

            this.uploadInput = new EventEmitter<UploadInput>();
            console.log('imageUrl', imageUrl)
    
            console.log('file', file)
            return this.http.post(imageUrl, body, {
                reportProgress: true,
                //observe: "events",
                headers: new HttpHeaders({
                    'Authorization': `Basic ${user_id}`,
                })
              })
                .pipe(
                    map((res: any) => { //IHttpResponse
                        const resBody = res;//JSON.parse(res._body);
                        console.log('resBody', resBody)
                        if (resBody.msg === "PDF_UPLOAD_SUCCESS") {
                            
                            console.log('body', resBody);
                            const pdfFile = resBody.pdfFile;
                            if(pdfFile){

                                const uuid = pdfFile.uuid;
                                this.router.navigate(['/images/pdf-viewer/' + uuid])
                            }
                            return {response: resBody, router:  this.router };

                        } else {
                            return {err:"Error"}
                            // Need to do something with image not saved error.
                        }
                    }),
                    catchError(err => {
                        console.warn('Error Saving Image to Base64', err)
                        return err
                    })
                )
        }
    }

    saveImageInfoEditToServer(imageUploadInfo: IImageUploadInfo) {
        const imageUrl = this.IMAGES_SERVER_URL + "/image/edit-image-info" //'/api/ngrxUploadImages';
        // headers: contentHeaders,
        return this.http.post(imageUrl, imageUploadInfo, { })
            .pipe(
                map((res: any) => { //ISaveResponse
                    return res;
                }),
                catchError(err => {
                    console.warn('Error Saving Image to Base64', err)
                    return err
                })
            )


    }

    saveDataImageToServer(imageUploadInfo: IImageUploadInfo, data: any): any { //, filename, originalname): Observable<any> {
        const user_id = imageUploadInfo.user_id;
        const jwtToken = imageUploadInfo.jwtToken;
        const imageUrl = this.IMAGES_SERVER_URL + "/image/data"


        const headers = new Headers();
        /** In Angular 5, including the header Content-Type can invalidate your request */
        //headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');

        if (data !== undefined) {
            // add base64 to imageUploadInfo
            const body: IImageUploadInfo = {
                ...imageUploadInfo,
                jwtToken: jwtToken,
                user_id: user_id,
                imageBase64: data,
            }


            this.uploadInput = new EventEmitter<UploadInput>();

            // headers: contentHeaders,
            return this.http.post(imageUrl, body, { })
                .pipe(
                    map((res: any) => { //IHttpResponse
                        const resBody = res;//JSON.parse(res._body);

                        if (resBody.msg === "IMAGE_UPLOAD_SUCCESS") {

                            return resBody;

                        } else {

                            // Need to do something with image not saved error.
                        }


                    }),
                    catchError(err => {
                        console.warn('Error Saving Image to Base64', err)
                        return err
                    })
                )
        }

    }

    // TODO reType this, figure out
    getResizeImageObserverable(passedImages: any[]) {
        // passedWidth is taken from the DataUrl and then passed through
        //Double checking that passedWidth is a number, if it is not resize of image will fail in Firefox with a non descript error.
        const imagesSit = passedImages.map((passedImage) => {
            const imgSrc = passedImage.imgSrc;
            const imageType = passedImage.imageType;
            const newWidth = passedImage.width;
            const filename = passedImage.filename;

            /** 
             * Creating a promise to resolve the data of the resized image, needs to be a promise/callback 
             * because firefox does not have immediate access to image "load", data will be "data:," 
            */
            // return new Promise(function (resolve, reject) {

            return new Observable(function (observer) {
                //Need to hone, for svg...
                const getSafeDataUrl = imgSrc.split('" style="')
                const image = new Image();

                function callback(dataURI: string, type: any) {

                    //We use a callback here to make sure fireFox has the new resized image.
                    const img = new Image();
                    img.src = dataURI;

                    return { filename: filename, imgSrc: dataURI }
                }

                const canvas = document.createElement('canvas');
                canvas.style.display = 'block';


                image.addEventListener("load", function () {
                    //Firefox does not have access to the image until it has been fully loaded, so we must wait for the image to fire event "load" when it is done
                    let tempWidth;
                    let oldWidth = 1;
                    let oldHeight = 1;

                    if (image.width !== 0) {
                        oldWidth = image.width;
                    }
                    if (image.height !== 0) {
                        oldHeight = image.height;
                    }

                    //May knock down the max height and width
                    const maxWidth = 2160;

                    if (oldWidth > maxWidth && (newWidth > maxWidth || newWidth === 0)) {
                        tempWidth = maxWidth;
                    }
                    else if (newWidth != null && newWidth !== undefined && newWidth > 0 && newWidth < maxWidth) {
                        tempWidth = newWidth
                    } else {
                        tempWidth = oldWidth;
                    }

                    const newHeight = Math.floor(oldHeight / oldWidth * tempWidth)

                    //Setting new image width, limited to max size and dependent upon passedWith.
                    const safeWidth = Number(tempWidth);
                    image.height = newHeight;
                    image.width = safeWidth;

                    //Give canvas the new dimensions.
                    canvas.width = safeWidth;
                    canvas.height = newHeight;

                    //Draw the image to canvas
                    const context = canvas.getContext('2d'); //, { preserveDrawingBuffer: true }); without comment there be an issue

                    if (context !== null) {

                        context.drawImage(image, 0, 0, safeWidth, newHeight);

                    }
                    const dataURL = canvas.toDataURL("image/png");

                    //return new image details to the promise.
                    observer.next(callback(dataURL, imageType))
                    observer.complete()
                }, false);

                image.onerror = function (err) {
                    console.error("IMAGE Error", err);
                    observer.error({ error: "Something wrong uploading the image" })
                }

                image.onabort = function (err) {
                    console.error("IMAGE Abort", err);
                }

                if (getSafeDataUrl !== undefined) {
                    const newSrc = getSafeDataUrl[0];

                    if (imageType === 'image/svg+xml') {
                        let svgSrc: any;

                        if (newSrc.includes('" class="')) {
                            const splitOutExtra = newSrc.split('" class="')
                            svgSrc = splitOutExtra[0];
                        } else {
                            svgSrc = newSrc;

                        }
                        //Is an svg, get the base64 encoded data out of image.

                        const cleanSrc = svgSrc.replace('data:image/svg+xml;base64,', '')
                        //Create new DomParser, to parse the SVG's Document after it has been decoded.
                        const newDomParser = new DOMParser()


                        const decodedSVG = atob(cleanSrc);

                        const parsedSvg = newDomParser.parseFromString(decodedSVG, "image/svg+xml");

                        /**
                         * Here is the kicker, SVG requires a width and height, not all SVG images have been saved with them, and we kind of strip them out... Anywho
                         * Chrome will infer the width of the image, and convert image with data. Firefox will not
                         * Must add "width" and "height" attributes to the svg document for it to display, we get Width from new Width, going to mess around in here
                         */

                        const widthAtt = document.createAttribute("width");
                        widthAtt.value = newWidth.toString();
                        const heightAtt = document.createAttribute("height");
                        heightAtt.value = newWidth.toString();

                        parsedSvg.documentElement.attributes.setNamedItem(heightAtt);
                        parsedSvg.documentElement.attributes.setNamedItem(widthAtt);

                        //After attributes have been added, serialize the SVG image back into text, which we will then reEncode in base64 
                        const serializer = new XMLSerializer
                        const serializedUpdatedSvg = serializer.serializeToString(parsedSvg);

                        const reEncode = btoa(serializedUpdatedSvg)

                        //Image.onLoad will not be called until src is defined, that happens here.
                        image.src = 'data:image/svg+xml;base64,' + reEncode

                    }
                    else if (imageType === 'image/gif') {
                        // Gif canvas cannot be converted to dataUrl in all browsers, Some will convert it to png of first frame.  
                        // So we cannot resize the gif here, resolve the promise with the original image, see if newWidth can be passed along to graphicsMagick.

                        observer.next({ filename: filename, imgSrc: newSrc });
                        observer.complete()
                    } else {
                        //Need to split out class if it is on the image 
                        const classFreeDataUrl = getSafeDataUrl[0].split('" class')
                        if (classFreeDataUrl !== undefined) {
                            image.src = classFreeDataUrl[0]
                        } else image.src = getSafeDataUrl[0]


                        // Png and Jpg do not need to be treated seperately 
                        //Found something that needs to be removed
                    }
                }

            })
        })

        return observableForkJoin(imagesSit)
    }


    postWithFile(postData: any, files: File[]) {
        const imageUrl = this.IMAGES_SERVER_URL + '/api/ngrxUploadImages';
        const headers = new HttpHeaders();
        const formData: FormData = new FormData();
        formData.append('files', files[0], files[0].name);

        if (postData !== "" && postData !== undefined && postData !== null) {
            for (const property in postData) {
                // eslint-disable-next-line no-prototype-builtins
                if (postData.hasOwnProperty(property)) {
                    formData.append(property, postData[property]);
                }
            }
        }

        const returnReponse = new Promise((resolve, reject) => {
            this.http.post(imageUrl, formData, {
                headers: headers
            }).pipe()
                .subscribe(
                    (res: any) => {
                        this.responseData = res['results']// .json();
                        resolve(this.responseData);
                    },
                    error => {
                        //this.router.navigate(['/login']);
                        reject(error);
                    }
                );
        });

        return returnReponse;
    }

    uploadFile(fileList: FileList): void {
        const url = this.IMAGES_SERVER_URL + '/api/ngrxUploadImages';

        if (fileList.length > 0) {
            const fileEntry: File = fileList[0];
            const formData: FormData = new FormData();

            formData.append('uploadFile', fileEntry, fileEntry.name);

            //headers: multiPartHeaders, 
            this.http.post(url, formData, { withCredentials: true })
                .pipe(
                    map((res: any) => { console.log('this is uploadFile res', res); return res['results'] }), catchError(error => _throw(error))
                ).subscribe(
                    data => data,
                    error => console.error(error)
                )
        }


    }

    generateId(): string {
        return Math.random().toString(36).substring(7);
    }

}